<template>
  <el-card>
    <user-table></user-table>
  </el-card>
</template>


<script>
import UserTable from "@/components/Tables/UserTable"

export default {
  name: "UserAdmin",

  components: {
    UserTable,
  },
}
</script>
