<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Edit {{ existing.name }}</span>
      <el-button type="text" @click="$modal.hide('user-edit-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <el-form
      @submit.native.prevent="update"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
      <el-form-item
        :error="form.errors.first('name') ? form.errors.first('name') : null"
        label="Name"
        required
      >
        <el-input
          v-model="user.name"
          type="text"
          placeholder="Name"
          @focus="form.errors.clear('name')"
          v-input-focus
        ></el-input>
      </el-form-item>
      <el-form-item
        :error="form.errors.first('email') ? form.errors.first('email') : null"
        label="Email"
        required
      >
        <el-input
          v-model="user.email"
          type="email"
          placeholder="Email"
          @focus="form.errors.clear('email')"
        ></el-input>
      </el-form-item>
      <el-form-item
        :error="
          form.errors.first('password') ? form.errors.first('password') : null
        "
        label="Password"
      >
        <el-col :span="12" style="padding-right: 0.5rem">
          <el-input
            v-model="user.password"
            type="password"
            placeholder="Password"
            @focus="form.errors.clear('password')"
          ></el-input>
        </el-col>
        <el-col :span="12" style="padding-left: 0.5rem">
          <el-input
            v-model="user.password_confirmation"
            type="password"
            placeholder="Confirm Password"
            @focus="form.errors.clear('password')"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-alert
        v-if="user.pending"
        type="info"
        :closable="false"
        title="This user has not yet activated their account. If you set a password, their account will become activated and any previously sent activation emails will be voided."
        style="margin-bottom: 1rem"
      ></el-alert>

      <el-form-item
        :error="form.errors.first('role') ? form.errors.first('role') : null"
        label="Role"
        required
      >
        <modal-select>
          <el-select
            v-model="user.role"
            :style="{ width: '100%' }"
            placeholder="Choose a Role..."
            :disabled="!$auth.check(['super'])"
          >
            <el-option
              v-for="item in roles"
              :key="item.slug"
              :label="item.name"
              :value="item.slug"
            ></el-option>
          </el-select>
        </modal-select>
      </el-form-item>
      <el-form-item
        :error="
          form.errors.first('companies') ? form.errors.first('companies') : null
        "
        :label="
          ['super', 'admin', 'employee'].indexOf(user.role) !== -1
            ? 'Represents'
            : 'Companies'
        "
      >
        <modal-select>
          <el-select
            v-model="userCompanies"
            :style="{ width: '100%' }"
            multiple
            filterable
            remote
            reserve-keyword
            placeholder="Type to search companies..."
            :remote-method="searchCompanies"
            :loading="loading"
            @focus="form.errors.clear('companies')"
          >
            <el-option
              v-for="item in companies"
              :key="item.slug"
              :label="item.name"
              :value="item.slug"
            ></el-option>
          </el-select>
        </modal-select>
      </el-form-item>
      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
      >
        Update
      </el-button>
    </el-form>
  </el-card>
</template>


<script>
import Form from "form-backend-validation"
import {mapActions, mapGetters} from "vuex"
import axios from "axios";

export default {
  name: "UserEditForm",

  props: {
    existing: {
      required: true,
    },
  },
  data() {
    return {
      user: {},
      userCompanies: [],
      form: new Form(),
      loading: false,
      filteredCompanies: []
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth/user",
      roles: "users/roles",
      companies: "companies/list",
    }),
  },

  methods: {
    ...mapActions({
      updateUser: "users/update",
      fetchCompanies: "companies/refresh",
    }),
    update() {
      this.loading = true;
      let formData = {
        name: this.user.name,
        email: this.user.email,
        password: this.user.password,
        password_confirmation: this.user.password_confirmation,
        companies: this.userCompanies,
      }

      if (this.$auth.check(["super"])) {
        formData["role"] = this.user.role;
      }

      this.axios
        .post("users/update/" + this.user.slug, formData)
        .then((response) => {
          this.updateUser(response.data.data);
          this.$modal.hide("user-edit-form");
          this.$message.success(response.data.message);
          this.loading = false;
        })
        .catch((resp) => {
          this.loading = false;
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp);
          } else {
            this.$root.globalRequestError(resp);
          }
        });
    },

    async getCompaniesOfUser() {
      this.user = this.$_.cloneDeep(this.existing);

      await axios.get('/user/companies/' + this.user.slug).then((response) => {
        this.userCompanies = response.data.data.map(company => company.slug);
      });
    },

    searchCompanies(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.fetchCompanies({ query: query }).then((companies) => {
            this.filteredCompanies = companies;
            this.loading = false;
          });
        }, 200);
      } else {
        this.filteredCompanies = [];
      }
    },
  },

  mounted() {
    this.getCompaniesOfUser();
  }
}
</script>
